export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "FIELDS": {
          "ARTICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículo"])},
          "UNIT_MEASUREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de Medida"])},
          "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
          "COST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo"])},
          "DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento 1"])},
          "DISCOUNT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento 2"])},
          "DISCOUNT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento 3"])},
          "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
          "TAX_PERCENTAJE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de Impuesto"])},
          "TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])}
        }
      }
    }
  })
}
