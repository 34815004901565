<template>
<ModalForm
    id="modalDetailInputEditForm"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <RowForm :twoColums="false">
        <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.ARTICLE") }}</label>
            <SelectFilterRemote
                class="itemControlLg"
                :name="'productId'"
                :options="comboArticles"
                @search="searchArticles"
                :disabled="readOnly"
                :placeholder="$t('FIELDS.ARTICLE')"
                @change="onChangeArticle"    
            />
    </RowForm>
    <RowForm :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.UNIT_MEASUREMENT") }}</label>
            <SelectOption
                class="itemControl"
                name="unitOfMeasurementId"
                :options="comboUnitMeasurement"
                :disabled="true"
            />
        </template>
        <template v-slot:col2>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.COLOR") }}</label>
            <Field  name="color" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        :placeholder='$t("FIELDS.COLOR")'
                        :validate-event="false"
                        :disabled="true"
                        :model-value="value"
                        
                    />
                </el-form-item>
            </Field>
        </template>
    </RowForm>

    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.QUANTITY") }}</label>
            <Field  name="quantity" v-slot="{value, field, errorMessage}" @input="onChangeQuantity" @change="onChangeQuantity">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        :placeholder='$t("FIELDS.QUANTITY")'
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                        
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.COST") }}</label>
             <InputText :name="'cost'" @input="onChangePrice"
                class="itemControl"
                :mask="maskMoney"
                :placeholder="$t('FIELDS.COST')"
                :disabled="readOnly"
            >
            </InputText>
        </template>
    </RowForm>

    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">% {{ $t("FIELDS.DISCOUNT") }}</label>
            <Field  name="discount1" v-slot="{value, field, errorMessage}" @input="onChangeDiscount" @change="onChangeDiscount">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        :placeholder='$t("FIELDS.DISCOUNT")'
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.AMOUNT") }}</label>
            <InputText :name="'amount'"
                class="itemControl"
                :mask="maskMoney"
                :placeholder="$t('FIELDS.AMOUNT')"
                :disabled="true">
            </InputText>
        </template>
    </RowForm>

    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.TAX_PERCENTAJE") }}</label>
            <SelectOption
                class="itemControl"
                name="taxId"
                :options="comboTaxPercentages"
                :disabled="true"
                @changeSelectValue="onChangeTaxPercentage"
            />
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.TAX") }}</label>
             <InputText :name="'taxPercentage'"
                class="itemControl"
                :mask="maskMoney"
                :placeholder="$t('FIELDS.TAX')"
                :disabled="true">
            </InputText>
        </template>
    </RowForm>

    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.TOTAL") }}</label>
            <InputText :name="'total'"
                class="itemControl"
                :mask="maskMoney"
                :placeholder="$t('FIELDS.TOTAL')"
                :disabled="true">
            </InputText>
        </template>
    </RowForm>      
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import InputText from "@/components/forms/InputText.vue"
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import SelectFilterRemote from '../../../components/forms/SelectFilterRemote.vue'

export default defineComponent({
    components:{
        Field
        , ModalForm
        , RowForm
        , SelectOption
        , InputText
        , SelectFilterRemote
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Detalle de Orden de Compra"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
        purchaseOrderId: {
            type: String,
            required: false,
            default: ""
        }
    },
    setup(props) {
        const { mode, currentOpenedItem, purchaseOrderId } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let controller = "PurchaseOrders";
        let quantity = 0;
        let price = 0;
        let globalPrice = 0;
        let discount = 0;
        let tax = 0;
        let taxPercentageIdSelect = ref('');

        const validationSchema = Validations.object().shape({
            productId: Validations.string().required().label("Artículo"),
            unitOfMeasurementId: Validations.string().required().label("Unidad de Medida"),
            color: Validations.string().label("Color"),
            quantity: Validations.number().required().label("Cantidad"),
            cost: Validations.number().required().label("Costo"),
            discount1: Validations.number().required().min(0, "El valor mínimo es 0").max(100, "El valor máximo es 100").label("Descuento"),
            amount: Validations.string().required().label("Subtotal"),
            taxId: Validations.string().required().label("Porcentaje de Impuesto"),
            taxPercentage: Validations.string().required().label("Impuesto"),
            total: Validations.string().required().label("Total")
        });

        let currentLineId = ref("");
        let articles = ref([])
        let unitMeasurements = ref([]);
        let taxPercentages = ref([]);
        let discApplieds = [];

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("MeasurementUnits", unitMeasurements);
            await getComboOptions("Taxes", taxPercentages);
        });

        //WATCHS
        watch(currentOpenedItem, async(newValue) => {
            if(newValue) {
                ApiService.get(`Products/${currentOpenedItem.value.productId}`).then(res => {
                    if(res.status == 200) {
                        articles.value = [];
                        articles.value.push(res.data);
                        callbackSearchArticles();
                        modalForm.value.$refs.form.setFieldValue("taxId", res.data.taxId);
                        tax = res.data.taxId;
                        taxPercentageIdSelect.value = res.data.taxId;
                    }
                }).catch(reject => {
                    getMessageError(reject, messageService);
                });
                await getComboOptions("MeasurementUnits", unitMeasurements);
            }
        });

        //VARAIBLES COMPUTADAS

        const initialValues = computed(() => {
            let oc = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                oc.productId = currentOpenedItem.value.productId;
                oc.unitOfMeasurementId = currentOpenedItem.value.unitOfMeasurementId;
                oc.quantity = currentOpenedItem.value.quantity;
                oc.cost = currentOpenedItem.value.cost;
                oc.color = currentOpenedItem.value.colorName;
                oc.discount1 = currentOpenedItem.value.discount1;
                // oc.discount2 = currentOpenedItem.value.discount2;
                // oc.discount3 = currentOpenedItem.value.discount3;
                oc.amount = currentOpenedItem.value.amount;
                oc.taxPercentage = currentOpenedItem.value.taxPercentage;
                oc.total = currentOpenedItem.value.total;
                price = currentOpenedItem.value.cost;
                quantity = currentOpenedItem.value.quantity;
                discount = currentOpenedItem.value.discount1;
                //tax = currentOpenedItem.value.taxPercentage;
            }
            
            return oc;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboArticles = computed(() => articles.value);
        const comboUnitMeasurement = computed(() =>  unitMeasurements.value);
        const comboTaxPercentages = computed(() => taxPercentages.value);

        //FUNCIONES
        const initDefaults = () => {         
            price = 0;
            quantity = 0;
            discount = 0
            tax = 0;
            discApplieds = [];
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            item.discount2 = 0;
            item.discount3 = 0;
            ApiService.post(`${controller}/Detail/${props.purchaseOrderId}`, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    item.productName = articles.value.some(a => a.id == item.productId) ? articles.value.find(a => a.id == item.productId).name : "";
                    item.unitOfMeasurementName = unitMeasurements.value.some(u => u.id == item.unitOfMeasurementId) ? unitMeasurements.value.find(u => u.id == item.unitOfMeasurementId).name : "";
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                modalForm.value.disabledLoading();
                getMessageError(reject, messageService, 'MessageError');
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/Detail/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            item.discount2 = 0;
            item.discount3 = 0;

            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        item.productName = articles.value.some(a => a.id == item.productId) ? articles.value.find(a => a.id == item.productId).name : "";
                        item.unitOfMeasurementName = unitMeasurements.value.some(u => u.id == item.unitOfMeasurementId) ? unitMeasurements.value.find(u => u.id == item.unitOfMeasurementId).name : "";
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                modalForm.value.disabledLoading();
                getMessageError(reject, messageService, 'MessageError');
            });
        }

        const getComboOptions = async(resource, container, parentId = "", filter = "", callback = undefined) => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?filter=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
                if(callback) {
                    callback(container.value);
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeArticle = (id) => {
            if(id) {
                let tmp = articles.value.find(a => a.id == id);
                if(tmp) {
                    discApplieds = [];
                    modalForm.value.$refs.form.setFieldValue("unitOfMeasurementId", tmp.unitOfMeasurementId);
                    modalForm.value.$refs.form.setFieldValue("cost", tmp.publicPrice);
                    price = tmp.publicPrice;
                    globalPrice = tmp.publicPrice;
                    discount = tmp.discountRateSupplier;
                    modalForm.value.$refs.form.setFieldValue("color", tmp.colorName);
                    modalForm.value.$refs.form.setFieldValue("discount1", tmp.discountRateSupplier);
                    if(tmp.taxId != "" && tmp.taxId != "00000000-0000-0000-0000-000000000000") {
                        modalForm.value.$refs.form.setFieldValue("taxId", tmp.taxId);
                        taxPercentageIdSelect.value = tmp.taxId;
                    }                    
                }
            }
        }

        const onChangePrice = (value) => {
            price = !isNaN(value) && value.trim() != "" ? parseFloat(value.trim()) : 0;
            globalPrice = price;
            price = globalPrice - (globalPrice * (discount / 100));
            updateCostValueField();
            updateTotalField();
            updateTax();
        }

        const onChangeQuantity = (value) => {
            if(!isNaN(value) && value.trim() != ""){
                quantity = parseFloat(value.trim());
                updateCostValueField();
                updateTax();
                updateTotalField();                
            }
        }

        const onChangeDiscount = (value) => {
            if(!isNaN(value) && value.trim() != ""){
                discount = parseFloat(value.trim());
            } else discount = 0;
            price = globalPrice - (globalPrice * (discount / 100));
            updateCostValueField();
            updateTotalField();
        }

        const onChangeTaxPercentage = (id) => {
            taxPercentageIdSelect.value = id;
            updateTax();
        }

        const updateTax = async() => {
            if(taxPercentageIdSelect.value != "" && taxPercentageIdSelect.value != "00000000-0000-0000-0000-000000000000") {
                await ApiService.get(`Taxes/${taxPercentageIdSelect.value}`).then(res => {
                    if(res.status == 200) {
                            tax = res.data.value/100;
                            modalForm.value.$refs.form.setFieldValue("taxPercentage", (quantity * price * tax));
                            updateTotalField();
                    }       
                }).catch(reject => {
                    getMessageError(reject, messageService);
                });
            }
        }

        const updateCostValueField = () => {
            let tmp = (quantity * price);
            modalForm.value.$refs.form.setFieldValue("amount", tmp);
        }

        const updateTotalField = () => {
            let subTotal = quantity * price;
            const amountTax = subTotal * (+tax);

            let total = subTotal + amountTax;
            total = total.toFixed(2);
            modalForm.value.$refs.form.setFieldValue("total", total);
        }

        const searchArticles = async(filter) => {
            if(filter.trim().length >= 3) {
                await getComboOptions("Products", articles, "", filter, callbackSearchArticles);
            }
        }

        const callbackSearchArticles = () => {
            articles.value = articles.value.map(a => {
                let article = {
                    id: a.id,
                    name: a.code + ' ' + a.name,
                    price: a.price,
                    unitOfMeasurementId: a.unitOfMeasurementId,
                    unitOfMeasurementName: a.unitOfMeasurementName,
                    minimunDiscount: a.minimunDiscount,
                    maximunDiscount: a.maximunDiscount,
                    publicPrice: a.publicPrice,
                    buyerPrice: a.buyerPrice,
                    wholesalePrice: a.wholesalePrice,
                    virtualExistence: a.virtualExistence,
                    currentExistence: a.currentExistence,
                    isInventory: a.isInventory,
                    alternateCode: a.alternateCode,
                    supplierKey: a.supplierKey,
                    productKey: a.productKey,
                    code: a.code,
                    cost: a.cost,
                    colorId: a.colorId,
                    colorName: a.colorName,
                    taxId: a.taxId,
                    taxName: a.taxName,
                    taxValue: a.taxValue,
                    discountRateSupplier: a.discountRateSupplier
                };

                return article;
            });
        }

        return {
            editForm
            , modalForm
            , validationSchema

            //Variables computadas
            , initialValues
            , readOnly
            , isEditing
            , comboArticles
            , comboUnitMeasurement
            , comboTaxPercentages

            //Funciones
            , handleCrudAction
            , initDefaults
            , onChangeArticle
            , onChangeQuantity
            , onChangePrice
            , onChangeDiscount
            // , onChangeDiscount2
            // , onChangeDiscount3
            , onChangeTaxPercentage
            , maskMoney: InputTextMaskTypes.MoneyShopping
            , searchArticles
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 272px !important;
    }

    .itemControlLg {
        width: 553px;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>

<i18n>
{
    "es": {
        "FIELDS": {
            "ARTICLE": "Artículo",
            "UNIT_MEASUREMENT": "Unidad de Medida",
            "QUANTITY": "Cantidad",
            "COST": "Costo",
            "DISCOUNT": "Descuento 1",
            "DISCOUNT2": "Descuento 2",
            "DISCOUNT3": "Descuento 3",
            "AMOUNT": "Subtotal",
            "TAX_PERCENTAJE": "Porcentaje de Impuesto",
            "TAX": "Impuesto",
            "TOTAL": "Total",
            "COLOR": "Color"
        }
    }
}
</i18n>
